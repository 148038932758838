<template>
    <div class="logo">
        <div class="logo_center__eVqSM">
            <svg viewBox="0 0 240 44">
                <g>
                    <path
                        d="M23.1865234,22.9389648c-0.5576172-3.9057617-3.409668-6.0136719-7.3154297-6.0136719 c-3.6577148,0-8.8037109,1.921875-8.8037109,11.6552734c0,5.331543,2.355957,10.9731445,8.4936523,10.9731445 c4.0917969,0,6.9438477-2.7275391,7.6254883-7.3154297h7.0678711c-1.3022461,8.3076172-6.4477539,12.8955078-14.6933594,12.8955078 C5.5175781,45.1337891,0,38.0039062,0,28.5805664c0-9.6713867,5.2695312-17.2348633,15.809082-17.2348633 c7.4394531,0,13.7631836,3.7197266,14.4453125,11.5932617H23.1865234z"
                    ></path>
                    <path
                        d="M40.0219727,0h7.0678711v16.4291992h0.1240234c1.7358398-2.9135742,5.3935547-5.0834961,9.609375-5.0834961 c6.9433594,0,11.3452148,3.7197266,11.3452148,10.9111328V44.265625h-7.0678711V24.1166992 c-0.1235352-5.0214844-2.1074219-7.1914062-6.2612305-7.1914062c-4.7119141,0-7.7495117,3.7197266-7.7495117,8.4316406V44.265625 h-7.0678711V0z"
                    ></path>
                    <path
                        d="M102.4541016,37.1362305c0,1.7358398,0.371582,2.4174805,1.6738281,2.4174805c0.434082,0,0.9916992,0,1.7358398-0.1240234 v4.8979492c-1.0541992,0.3720703-3.2856445,0.8061523-4.4638672,0.8061523c-2.8515625,0-4.8974609-0.9921875-5.4555664-3.9057617 c-2.7900391,2.7275391-7.3154297,3.9057617-11.0976562,3.9057617c-5.765625,0-10.9731445-3.1000977-10.9731445-9.3618164 c0-7.9975586,6.3857422-9.2993164,12.3374023-9.9814453c5.0834961-0.9296875,9.609375-0.371582,9.609375-4.5253906 c0-3.6582031-3.7817383-4.3398438-6.6337891-4.3398438c-3.9677734,0-6.7573242,1.6118164-7.0673828,5.0834961h-7.0678711 c0.4960938-8.2456055,7.5019531-10.6630859,14.5693359-10.6630859c6.2617188,0,12.8334961,2.5415039,12.8334961,9.2993164 V37.1362305z M93.3862305,28.2084961c-2.1699219,1.4257812-5.5795898,1.3637695-8.6791992,1.921875 c-3.0380859,0.4960938-5.7661133,1.6118164-5.7661133,5.331543c0,3.1621094,4.0297852,4.0917969,6.5097656,4.0917969 c3.1000977,0,7.9355469-1.6118164,7.9355469-6.0756836V28.2084961z"
                    ></path>
                    <path
                        d="M113.081543,12.2133789h6.6958008v4.7119141l0.1240234,0.1240234 c2.1079102-3.5336914,5.765625-5.7036133,9.9814453-5.7036133c6.9433594,0,11.3452148,3.7197266,11.3452148,10.9111328V44.265625 h-7.0678711V24.1166992c-0.1235352-5.0214844-2.1074219-7.1914062-6.2612305-7.1914062 c-4.7119141,0-7.7495117,3.7197266-7.7495117,8.4316406V44.265625h-7.0678711V12.2133789z"
                    ></path>
                    <path
                        d="M148.4287109,12.2133789h6.6958008v4.7119141l0.1240234,0.1240234 c2.1079102-3.5336914,5.765625-5.7036133,9.9814453-5.7036133c6.9433594,0,11.3452148,3.7197266,11.3452148,10.9111328V44.265625 h-7.0673828V24.1166992c-0.1240234-5.0214844-2.1079102-7.1914062-6.2617188-7.1914062 c-4.7119141,0-7.7495117,3.7197266-7.7495117,8.4316406V44.265625h-7.0678711V12.2133789z"
                    ></path>
                    <path
                        d="M190.5336914,30.0683594c0,5.0219727,2.7280273,9.4853516,8.6176758,9.4853516 c4.0917969,0,6.5717773-1.7978516,7.8115234-5.331543h6.6958008c-1.550293,7.0058594-7.5019531,10.9116211-14.5073242,10.9116211 c-10.043457,0-15.6850586-7.0058594-15.6850586-16.8632812c0-9.1132812,5.9516602-16.9248047,15.4990234-16.9248047 c10.1054688,0,16.3051758,9.1132812,15.1269531,18.7226562H181.5336914z "
                    ></path>
                    <path
                        d="M224.0743164,0h7.0673828v44.265625h-7.0673828V0z"
                    ></path>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: "Logo",
};
</script>

<style scoped>
.logo {
    margin: 5% auto 5% auto;
    width: 75%;
    max-width: 700px;
}

@media (max-width: 960px) {
    .logo {
        margin-top: 15%;
    }
}
.logo svg {
    fill: none;
    stroke: #000;
    stroke-dasharray: 30;
    stroke-width: 7.5px;
    overflow: visible;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: 10s;
}

.logo svg path {
    stroke: black;
}

.logo svg path {
    animation: dash 30s linear alternate infinite;
}

@keyframes dash {
    to {
        stroke-dashoffset: 30;
        stroke-dasharray: 50;
    }
}
</style>
